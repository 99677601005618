import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material"; // Ensure this import is correct

const DialogueBox = () => {
  const [open, setOpen] = useState(true); // Initially set to true to show the dialog
  const navigate = useNavigate();

  // Handle close button
  const handleClose = () => {
    setOpen(false);
  };

  const handleRedirectToSettings = () => {
    navigate("/settings");
    setOpen(false);
  };

  return (
    <Dialog
      open={open} // Correctly use the open state here
      // onClose={handleClose}
      onClose={(e, reason) => {
        // Prevent closing on backdrop click
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      maxWidth="xl"
      aria-labelledby="responsive-dialog-title"
      // BackdropProps={{
      //   // Custom backdrop props
      //   // You can adjust as necessary
      //   onClick: (e) => {
      //     e.stopPropagation(); // Prevent the backdrop click from closing the dialog
      //   },
      // }}
    >
      <DialogTitle id="responsive-dialog-title">
        Please configure your Follow Up Boss API key to access app features.
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          To continue using the app, please navigate to the settings page and
          configure your API key.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="outlined" onClick={handleRedirectToSettings}>
          Go to settings
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogueBox;
