import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userData: null,
    countData: null,
  },
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setCountData: (state, action) => {
      state.countData = action.payload;
    },
    clearCountData(state) {
      state.countData = {};
    },
    // clearHotelDetails(state) {
    //   state.hotelDetails = {};
    // },
    // clearInputData(state) {
    //   state.inputData = {};
    // },
    // clearUser: (state) => {
    //   state.user = null; // Clear user authentication details
    // },
  },
});

export const { setUserData, setCountData, clearCountData } = userSlice.actions;
export default userSlice.reducer;
