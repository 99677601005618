import { styled } from '@mui/material/styles';
import { TableCell, TableContainer } from '@mui/material';

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(4),
  overflowX: 'auto', // Enable horizontal scrolling
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: '#02524b',
    color: theme.palette.common.white,
    padding: '12px',
    textAlign: 'center',
    fontWeight: 'bold',
    height: '56px',
    minWidth: '120px',
    maxWidth: '200px',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    '&.MuiTableSortLabel-root': {
      color: 'inherit',
    },
    '&.MuiTableSortLabel-root:hover': {
      color: theme.palette.grey[200],
    },
    '&.MuiTableSortLabel-root.Mui-active': {
      color: theme.palette.common.white,
    },
  }));
