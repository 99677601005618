import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Typography, Box } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import AuthLayout from "./AuthLayout";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  String(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
);

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [error, setError] = useState("");
  const { register } = useAuth();
  const navigate = useNavigate();
  console.log(process.env.REACT_APP_API_BASE_URL, "base url");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    console.log("Submitting registration with promo code:", promoCode); // Add this line
    try {
      console.log("trying to call register api");
      const response = await register(email, password, promoCode);
      console.log("Registration response:", response); // Add this line
      if (response.redirectToDashboard) {
        navigate("/dashboard");
      } else if (response.sessionId) {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: response.sessionId,
        });
        if (error) {
          setError(error.message);
        }
      } else {
        navigate("/subscription-plan");
      }
    } catch (error) {
      setError(error.message || "Failed to register. Please try again.");
    }
  };

  return (
    <AuthLayout title="Register">
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          margin="normal"
          fullWidth
          name="promoCode"
          label="Promo Code (Optional)"
          id="promoCode"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Register
        </Button>
        {error && <Typography color="error">{error}</Typography>}
      </Box>
    </AuthLayout>
  );
};

export default Register;
