import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';

const AgentColorContext = createContext();

export const useAgentColors = () => useContext(AgentColorContext);

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3004';

export const AgentColorProvider = ({ children }) => {
  const { user } = useAuth();
  const [agentColors, setAgentColors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAgentColors = useCallback(async () => {
    if (!user) {
      console.log('No user, skipping fetchAgentColors');
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${API_BASE_URL}/api/agent-colors/colors`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAgentColors(response.data.reduce((acc, { agentName, color }) => {
        acc[agentName] = color;
        return acc;
      }, {}));
      setError(null);
    } catch (error) {
      console.error('Error fetching agent colors:', error.response ? error.response.data : error.message);
      setError('Failed to fetch agent colors');
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    console.log('AgentColorProvider user:', user);
    if (user) {
      fetchAgentColors();
    }
  }, [user, fetchAgentColors]);

  const saveAgentColor = async (agentName, color) => {
    const token = localStorage.getItem('token');
    try {
      await axios.post(`${API_BASE_URL}/api/agent-colors/save-color`, { agentName, color }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAgentColors((prev) => ({ ...prev, [agentName]: color }));
    } catch (error) {
      console.error('Error saving agent color:', error);
    }
  };

  return (
    <AgentColorContext.Provider value={{ agentColors, isLoading, error, fetchAgentColors, saveAgentColor }}>
      {children}
    </AgentColorContext.Provider>
  );
};