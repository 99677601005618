import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SidebarNavigation from "./components/SidebarNavigation";
import DailyLogTable from "./components/DailyLogTable";
import StageCounts from "./components/StageCounts";
import SelectionComponent from "./components/SelectionComponent";
import Scoreboard from "./components/Scoreboard";
import LeadInsights from "./components/leadInsights/LeadInsights";
import Login from "./components/Login";
import Register from "./components/Register";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import Settings from "./components/Settings";
import APIKeyForm from "./components/APIKeyForm";
import SubscriptionManagement from "./components/SubscriptionManagement";
import { lightTheme, darkTheme } from "./theme";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import { AgentColorProvider } from "./contexts/AgentColorContext";
import ErrorBoundary from "./components/ErrorBoundary";
import axios from "axios";
import { io } from "socket.io-client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import LeadClaimDashboard from "./components/LeadClaimDashboard";

console.log("App.js is being executed");

const API_BASE_URL =
  // process.env.REACT_APP_API_BASE_URL || "http://localhost:3004";
  process.env.REACT_APP_API_BASE_URL;

console.log(API_BASE_URL);
const socket = io(API_BASE_URL);

const SubscriptionSuccess = () => {
  console.log("SubscriptionSuccess component rendered");
  const location = useLocation();
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    console.log("SubscriptionSuccess useEffect called");
    const params = new URLSearchParams(location.search);
    const sessionId = params.get("session_id");
    console.log(sessionId);

    console.log("Session ID:", sessionId);

    const verifySubscription = async () => {
      console.log("Hello from successfully registered page");
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      console.log(API_BASE_URL, "API_BASE_URL");
      if (sessionId) {
        console.log("Hello from successfully registered page");
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/auth/register/success?session_id=${sessionId}`
          );
          // const data = await response.json();
          const data = await response.data;
          console.log("Hello from successfully registered page");
          console.log(data);
          const token = data.user.token;
          console.log(token);
          if (token) {
            await login(token);
            navigate("/settings");
          } else {
            navigate("/dashboard");
          }
        } catch (error) {
          console.error("Error verifying subscription:", error);
          navigate("/dashboard");
        }
      }
    };

    verifySubscription();
  }, [location, navigate, login]);

  return (
    <Box sx={{ mt: 4, textAlign: "center" }}>
      <h1>Verifying your subscription...</h1>
    </Box>
  );
};

const PrivateRoute = ({ children }) => {
  console.log("PrivateRoute rendered");
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <div>Loading...</div>;
  }
  console.log("user from app route");
  console.log(user);
  console.log(user?.fubApiKey);
  if (!user) {
    console.log("User not authenticated, redirecting to login");
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (
    user.subscriptionStatus !== "active" &&
    location.pathname !== "/subscription" &&
    location.pathname !== "/register-payment" &&
    location.pathname !== "/subscription-success" &&
    location.pathname !== "/settings"
  ) {
    console.log("User not subscribed, redirecting to register-payment");
    return (
      <Navigate to="/register-payment" state={{ from: location }} replace />
    );
  }

  console.log("User authenticated and subscribed, rendering children");
  return children;
};

const AppContent = () => {
  console.log("AppContent rendered");
  const [darkMode, setDarkMode] = useState(false);
  const theme = darkMode ? darkTheme : lightTheme;
  const { user } = useAuth();
  console.log(user);
  const location = useLocation();

  console.log("Current route:", location.pathname);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        // component="main"
        sx={{ display: "flex" }}
        width="100%"
      >
        {user && (
          <SidebarNavigation darkMode={darkMode} setDarkMode={setDarkMode} />
        )}
        <Box
          p={4}
          // component="main"
          // sx={{ flexGrow: 1, p: 0 }}
          // display="flex"
          // width="100%"
          sx={{ display: "flex", flex: 1 }}
        >
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            {/* ----Conflicts are below-- */}
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <SelectionComponent />
                </PrivateRoute>
              }
            />

            <Route
              path="/smart-lists"
              element={
                <PrivateRoute>
                  <DailyLogTable />
                </PrivateRoute>
              }
              socket={socket}
            />

            <Route
              path="/stage-counts"
              element={
                <PrivateRoute>
                  <StageCounts />
                </PrivateRoute>
              }
            />
            <Route
              path="/scoreboard"
              element={
                <PrivateRoute>
                  <Scoreboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/lead-insights"
              element={
                <PrivateRoute>
                  <LeadInsights />
                </PrivateRoute>
              }
            />
            <Route
              path="/api-key"
              element={
                <PrivateRoute>
                  <APIKeyForm />
                </PrivateRoute>
              }
            />
            <Route
              path="/lead-claims"
              element={
                <PrivateRoute>
                  <LeadClaimDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <Settings />
                </PrivateRoute>
              }
            />
            {/* <Route path="/" element={<PrivateRoute><SelectionComponent /></PrivateRoute>} />
            <Route path="/daily-log" element={<PrivateRoute><DailyLogTable socket={socket} /></PrivateRoute>} />
            <Route path="/stage-counts" element={<PrivateRoute><StageCounts /></PrivateRoute>} />
            <Route path="/scoreboard" element={<PrivateRoute><Scoreboard /></PrivateRoute>} />
            <Route path="/lead-insights" element={<PrivateRoute><LeadInsights /></PrivateRoute>} />
            <Route path="/api-key" element={<PrivateRoute><APIKeyForm /></PrivateRoute>} />
            <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} /> */}
            {/* ----Conflicts are below-- */}
            <Route path="/subscription" element={<SubscriptionManagement />} />
            <Route
              path="/register-payment"
              element={<SubscriptionManagement />}
            />
            <Route
              path="/subscription-success"
              element={<SubscriptionSuccess />}
            />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <SelectionComponent />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<div>404 - Not Found</div>} />
          </Routes>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

const App = () => {
  console.log("App component rendered");
  const [stripePromise, setStripePromise] = useState(null);
  const queryClient = new QueryClient(); // Add this line

  useEffect(() => {
    const initializeStripe = async () => {
      console.log("Initializing Stripe...");
      try {
        const stripe = await loadStripe(
          String(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
        );
        console.log("Stripe initialized:", stripe);
        setStripePromise(stripe);
      } catch (error) {
        console.error("Error initializing Stripe:", error);
      }
    };

    initializeStripe();
  }, []);

  if (!stripePromise) {
    return <div>Loading Stripe...</div>;
  }

  return (
    <Router>
      <AuthProvider>
        <ErrorBoundary>
          <AgentColorProvider>
            <QueryClientProvider client={queryClient}>
              <Elements stripe={stripePromise}>
                <AppContent />
              </Elements>
            </QueryClientProvider>
          </AgentColorProvider>
        </ErrorBoundary>
      </AuthProvider>
    </Router>
  );
};

export default App;
