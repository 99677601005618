import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import DatePicker from "react-datepicker";
import { Box, Container, Grid, styled, Button, Autocomplete, TextField, Typography, Snackbar, CircularProgress } from "@mui/material";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:3004";

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  fontSize: "1rem",
  minWidth: "140px",
  marginTop: "2.2rem",
  position: "relative", // Keep the relative position for layout integrity
}));

const FilterLeadClaims = ({
  setFilteredClaimEvents,
  setFilterClaimErrorMessage,
  data,
  setFilterAgentPerformanceMetrics,
  totalPages,
  setTotalFilteredPages,
  allClaimEvents,
  setFilteredTotalPagesForPerformanceMetrics,
  filteredTotalPagesForPerformanceMetrics,
  totalPagesForPerformanceMetrics,
}) => {
  const [agents, setAgents] = useState([]);
  const [ponds, setPonds] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [selectedPond, setSelectedPond] = useState("");
  const [startDate, setStartDate] = useState("");
  const [selectedClaimType, setSelectedClaimType] = useState("");
  const [endDate, setEndDate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // for "No filter applied" message
  const [noFilterApplied, setNoFilterApplied] = useState(false);
  const [loading, setLoading] = useState(false); // Track loading state

  const claimType = [
    { id: 2, label: "Pond Claim", value: "ClaimedFromPond" },
    { id: 3, label: "Reassigned", value: "Reassigned" },
    { id: 4, label: "Released To Pond", value: "ReleasedToPond" },
  ];

  const fetchAgents = useCallback(async () => {
    try {
      const token = Cookies.get("token");
      const response = await axios.get(`${API_BASE_URL}/api/users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const sortedAgents = Object.entries(response.data).sort(([, a], [, b]) =>
        a.localeCompare(b)
      );
      return sortedAgents.map(([id, name]) => ({ value: id, label: name }));
    } catch (error) {
      setErrorMessage("Error fetching agents. Please try again.");
      return [];
    }
  }, []);

  const fetchPonds = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_BASE_URL}/api/ponds`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const sortedPonds = response.data.sort((a, b) => a.name.localeCompare(b.name));
      return sortedPonds.map(({ id, name }) => ({ value: id, label: name }));
    } catch (error) {
      setErrorMessage("Error fetching ponds. Please try again.");
      return [];
    }
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const [agentOptions, pondOptions] = await Promise.all([fetchAgents(), fetchPonds()]);
      setAgents(agentOptions);
      setPonds(pondOptions);
    } catch (error) {
      setErrorMessage("Error fetching data. Please try again.");
    }
  }, [fetchAgents, fetchPonds]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleResetFilters = () => {
    setSelectedAgent("");
    setSelectedPond("");
    setSelectedClaimType("");
    setStartDate("");
    setEndDate("");
    setFilteredClaimEvents(allClaimEvents);
    setTotalFilteredPages(totalPages);
    setFilteredTotalPagesForPerformanceMetrics(totalPagesForPerformanceMetrics);
    setFilterAgentPerformanceMetrics(data);
    setFilterClaimErrorMessage("");
    setNoFilterApplied(false); // Reset no filter applied message
  };

  const fetchClaimEvents = useCallback(async () => {
    setLoading(true); // Start loading
    try {
      const token = localStorage.getItem("token");
      const claimTypeValue = claimType.find((type) => type.label === selectedClaimType)?.value;
      const response = await axios.get(`${API_BASE_URL}/api/claim-events/filter-events`, {
        params: {
          selectedClaimType: claimTypeValue,
          selectedAgent: selectedAgent?.label || "",
          selectedPond: selectedPond?.label || "",
          page: currentPage,
          limit: itemsPerPage,
        },
      });

      if (response.data.message) {
        setFilterClaimErrorMessage(response.data.message);
        setFilteredClaimEvents([]);
      }

      if (response.data.events) {
        const eventResponse = response.data.events;
        setFilterClaimErrorMessage("");
        setFilteredClaimEvents(eventResponse);
        setTotalFilteredPages(response.data?.totalPages);
      }

      if (selectedAgent && response.data?.performance?.length > 0) {
        setFilterAgentPerformanceMetrics(response.data.performance);
        setFilteredTotalPagesForPerformanceMetrics(response.data.totalAgentPerformancePages);
      } else {
        setFilteredTotalPagesForPerformanceMetrics(filteredTotalPagesForPerformanceMetrics);
        setFilterAgentPerformanceMetrics(data);
      }
    } catch (error) {
      console.error("Error fetching claim events:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  }, [selectedAgent, selectedPond, selectedClaimType, startDate, endDate, currentPage, itemsPerPage]);

  const handleFilterClick = () => {
    if (!selectedAgent && !selectedPond && !selectedClaimType && !startDate && !endDate) {
      setNoFilterApplied(true);
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 3000);
    } else {
      fetchClaimEvents();
    }
  };

  return (
    <Box width="100%">
      <Snackbar
        open={snackbarOpen}
        message="No filter applied"
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      />
      <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap={2} width="100%">
        {/* Agent Dropdown */}
        <Box width="100%">
          <Typography variant="h6" gutterBottom>Agents</Typography>
          <Autocomplete
            options={agents}
            value={selectedAgent}
            onChange={(event, value) => setSelectedAgent(value)}
            renderInput={(params) => <TextField {...params} label="Select agents" variant="outlined" />}
          />
        </Box>

        {/* Pond Dropdown */}
        <Box width="100%">
          <Typography variant="h6" gutterBottom>Ponds</Typography>
          <Autocomplete
            options={ponds}
            value={selectedPond}
            onChange={(event, value) => setSelectedPond(value)}
            renderInput={(params) => <TextField {...params} label="Select ponds" variant="outlined" />}
          />
        </Box>

        {/* Claim Type Dropdown */}
        <Box width="100%">
          <Typography variant="h6" gutterBottom>Claim Type</Typography>
          <Autocomplete
            options={claimType.map(c => c.label)}
            value={selectedClaimType}
            onChange={(event, value) => setSelectedClaimType(value)}
            renderInput={(params) => <TextField {...params} label="Select type" variant="outlined" />}
          />
        </Box>

        {/* Apply Filter and Reset Buttons */}
        <Box display="flex" gap={1} flexDirection={{ xs: "column", lg: "row" }} width="100%">
          <StyledButton onClick={handleFilterClick} variant="contained">
            {loading ? <CircularProgress size={24} color="inherit" /> : "Filter"} {/* Show loading wheel */}
          </StyledButton>
          <StyledButton onClick={handleResetFilters} variant="contained">Reset</StyledButton>
        </Box>
      </Box>
    </Box>
  );
};

export default FilterLeadClaims;
