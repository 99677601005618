import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { TextField, Button, Typography, Container, Box } from '@mui/material';

const APIKeyForm = () => {
  const [apiKey, setApiKey] = useState('');
  const [error, setError] = useState('');
  const { updateApiKey } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateApiKey(apiKey);
      setApiKey('');
      setError('');
    } catch (error) {
      setError('Failed to update API key');
    }
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Update Follow Up Boss API Key
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="apiKey"
            label="API Key"
            name="apiKey"
            autoFocus
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Update API Key
          </Button>
          {error && <Typography color="error">{error}</Typography>}
        </Box>
      </Box>
    </Container>
  );
};

export default APIKeyForm;