import React from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Paper,
  Typography,
} from "@mui/material";

const steps = [
  {
    label: "Released to buyer by Lee Adkins",

    // (
    //   <Box gap={2}>
    //     <Typography>Released to buyer by Lee Adkins</Typography>
    //     <Typography>2:29 am</Typography>
    //   </Box>
    // ),
  },
  {
    label: "Assigned to Lee Adkins  by Lee Adkins",
    // description: `4:30 am `,
  },
  {
    label: "Claimed By from buyer Lee adkins and assigned to Joey",
    // description: `8:30 pm `,
  },
];

const ShowStepper = ({ leadDetails }) => {
  console.log(leadDetails);
  // const [activeStep, setActiveStep] = React.useState(0);
  const recentHistory = leadDetails?.recentHistory || [];
  // console.log(recentHistory, "recentHistory");

  return (
    <Box>
      <Stepper orientation="vertical" activeStep={-1}>
        {recentHistory.map((historyItem, index) => (
          <Step key={historyItem._id}>
            {/* <StepLabel>

              {historyItem.claimType === 'ClaimedFromPond' &&  "Claimed by Lee adkins from ${historyItem.previousAssignment.assignedToName} and assigned to ${historyItem.newAssignment.assignedToName}"}
              {`${
                historyItem.claimType === "ReleasedToPond"
                  ? "Released"
                  : "Assigned"
              } to   ${historyItem.claimedByName} by Lee Adkins`}{" "}
            </StepLabel> */}

            <StepLabel>
              {
                historyItem.claimType === "ClaimedFromPond" &&
                historyItem.previousAssignment.assignmentType === "pond" &&
                historyItem.newAssignment.assignmentType === "agent"
                  ? `Claimed by Lee Adkins from ${historyItem?.previousAssignment?.pondName} and assigned to ${historyItem.newAssignment.assignedToName}`
                  : historyItem.claimType === "ReleasedToPond" &&
                    historyItem.previousAssignment.assignmentType === "pond" &&
                    historyItem.newAssignment.assignmentType === "pond"
                  ? // Released from AcrePro Dead Leads by Lee Adkins to Long-Term Nurture POND
                    `Released from ${historyItem.previousAssignment?.pondName} by Lee Adkins to ${historyItem.newAssignment?.pondName}`
                  : historyItem.claimType === "ReleasedToPond" &&
                    historyItem.previousAssignment.assignmentType === "agent" &&
                    historyItem.newAssignment.assignmentType === "pond"
                  ? `Released to ${historyItem.newAssignment?.pondName} by Lee Adkins`
                  : historyItem.claimType === "FTC"
                  ? `Assigned to ${historyItem.newAssignment.assignedToName} by Lee Adkins`
                  : `Assigned to ${historyItem.newAssignment.assignedToName} by Lee Adkins`
                // : historyItem.claimType === "Reassigned"
                // ? `Assigned to ${historyItem.newAssignment.assignedToName} by Lee Adkins`
              }
            </StepLabel>

            <StepContent>
              <Typography variant="body2">
                {`Stage: ${historyItem.leadStage}`}
              </Typography>
              <Typography variant="body2">
                {`Claimed At: ${new Date(
                  historyItem.claimedAt
                ).toLocaleString()}`}
              </Typography>
              <Typography variant="body2">
                {`Previous Assignment: ${historyItem.previousAssignment.assignedToName}`}
              </Typography>
              <Typography variant="body2">
                {`New Assignment: ${historyItem.newAssignment.assignedToName}`}
              </Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ShowStepper;
