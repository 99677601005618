// src/theme.js
import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#02524b',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#93c9b5',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
    text: {
      primary: '#333333',
      secondary: '#333333',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
    fontSize: 14,
    fontWeightBold: 600,
  },
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          overflow: 'hidden',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#02524b !important',
          '& .MuiTableCell-head': {
            color: '#ffffff !important',
            fontWeight: '600 !important',
            padding: '16px !important',
            textAlign: 'center !important',
            textTransform: 'uppercase !important',
            letterSpacing: '1px !important',
            borderBottom: 'none !important',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: '#02524b',
          color: '#ffffff',
          fontWeight: 600,
          padding: '16px',
          textAlign: 'center',
          textTransform: 'uppercase',
          letterSpacing: '1px',
          borderBottom: 'none',
        },
        body: {
          padding: '12px',
          textAlign: 'center',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: '#f9f9f9',
          },
          '&:nth-of-type(even)': {
            backgroundColor: '#ffffff',
          },
          '&:hover': {
            backgroundColor: '#f1f1f1',
          },
        },
      },
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#121212',
      paper: '#1d1d1d',
    },
    text: {
      primary: '#ffffff',
      secondary: '#b0bec5',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
    fontSize: 14,
    fontWeightBold: 600,
  },
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          overflow: 'hidden',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#1d1d1d !important',
          '& .MuiTableCell-head': {
            color: '#ffffff !important',
            fontWeight: '600 !important',
            padding: '16px !important',
            textAlign: 'center !important',
            textTransform: 'uppercase !important',
            letterSpacing: '1px !important',
            borderBottom: '1px solid #333333 !important',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: '#1d1d1d',
          color: '#ffffff',
          fontWeight: 600,
          padding: '16px',
          textAlign: 'center',
          textTransform: 'uppercase',
          letterSpacing: '1px',
          borderBottom: '1px solid #333333',
        },
        body: {
          padding: '12px',
          textAlign: 'center',
          backgroundColor: 'transparent',
          color: '#ffffff',
          borderBottom: '1px solid #333333',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: '#2d2d2d',
          },
          '&:nth-of-type(even)': {
            backgroundColor: '#1d1d1d',
          },
          '&:hover': {
            backgroundColor: '#333333',
          },
        },
      },
    },
  },
});

export { lightTheme, darkTheme };