import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  useTheme,
  Box,
  Divider,
} from "@mui/material";
import {
  Home as HomeIcon,
  BarChart as BarChartIcon,
  Person as PersonIcon,
  Menu as MenuIcon,
  Close as CloseIcon,
  Lightbulb as LightbulbIcon,
  Brightness4 as Brightness4Icon,
  Brightness7 as Brightness7Icon,
  Settings as SettingsIcon,
  ExitToApp as LogoutIcon,
  AssignmentInd as AssignmentIndIcon,
} from "@mui/icons-material";
import { useAuth } from "../contexts/AuthContext";

const SidebarNavigation = ({ darkMode, setDarkMode }) => {
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();
  const theme = useTheme();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleLogout = () => {
    logout();
    Cookies.remove("token");

    navigate("/login");
  };

  const navItems = [
    { name: " Select Metrics", path: "/", icon: <HomeIcon /> },
    {
      name: "Smart Lists Reports",
      path: "/smart-lists",
      icon: <BarChartIcon />,
    },
    { name: "Stage Reports", path: "/stage-counts", icon: <BarChartIcon /> },
    { name: "Scoreboard", path: "/scoreboard", icon: <PersonIcon /> },
    { name: "Lead Insights", path: "/lead-insights", icon: <LightbulbIcon /> },
    { name: "Lead Claims", path: "/lead-claims", icon: <AssignmentIndIcon /> },
    { name: "Settings", path: "/settings", icon: <SettingsIcon /> },
  ];

  return (
    <Drawer
      variant="permanent"
      open={isOpen}
      sx={{
        width: isOpen ? 270 : 56,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isOpen ? 270 : 56,
          boxSizing: "border-box",
          backgroundColor: theme.palette.background.default,
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      }}
    >
      <List
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <ListItem
            sx={{
              display: "flex",
              justifyContent: isOpen ? "space-between" : "center",
              padding: 2,
            }}
          >
            {isOpen && (
              <Typography variant="h6" noWrap component="div">
                Smart List Zero
              </Typography>
            )}
            <IconButton onClick={toggleSidebar}>
              {isOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </ListItem>
          {navItems.map((item) => (
            <ListItem
              button
              key={item.name}
              component={Link}
              to={item.path}
              selected={location.pathname === item.path}
              sx={{
                justifyContent: isOpen ? "flex-start" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {item.icon}
              </ListItemIcon>
              {isOpen && <ListItemText primary={item.name} />}
            </ListItem>
          ))}
        </Box>
        <Box sx={{ pb: 2 }}>
          <Divider />
          <ListItem
            button
            onClick={toggleDarkMode}
            sx={{
              justifyContent: isOpen ? "flex-start" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isOpen ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
            </ListItemIcon>
            {isOpen && <ListItemText primary="Toggle Dark Mode" />}
          </ListItem>
          <ListItem
            button
            onClick={handleLogout}
            sx={{
              justifyContent: isOpen ? "flex-start" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isOpen ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <LogoutIcon />
            </ListItemIcon>
            {isOpen && <ListItemText primary="Logout" />}
          </ListItem>
        </Box>
      </List>
    </Drawer>
  );
};

export default SidebarNavigation;
