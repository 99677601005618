import axios from 'axios';

const FUB_BASE_URL = 'https://api.followupboss.com/v1';

const fubService = {
  apiKey: null,

  setApiKey(apiKey) {
    this.apiKey = apiKey;
  },

  async getLeads() {
    if (!this.apiKey) {
      throw new Error('FUB API key not set');
    }

    try {
      const response = await axios.get(`${FUB_BASE_URL}/people`, {
        auth: {
          username: this.apiKey,
          password: 'X'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching leads from FUB:', error);
      throw error;
    }
  },

  async getEvents() {
    if (!this.apiKey) {
      throw new Error('FUB API key not set');
    }

    try {
      const response = await axios.get(`${FUB_BASE_URL}/events`, {
        auth: {
          username: this.apiKey,
          password: 'X'
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching events from FUB:', error);
      throw error;
    }
  },

  // Add more methods for other FUB API endpoints as needed
};

export default fubService;