// src/components/AuthLayout.js
import React from 'react';
import { Container, Paper, Box, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const AuthLayout = ({ children, title }) => {
  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component="h1" variant="h5" gutterBottom>
            {title}
          </Typography>
          {children}
        </Box>
      </Paper>
      <Box mt={2} textAlign="center">
        <Link component={RouterLink} to="/login" variant="body2" sx={{ mr: 2 }}>
          Login
        </Link>
        <Link component={RouterLink} to="/register" variant="body2" sx={{ mr: 2 }}>
          Register
        </Link>
        <Link component={RouterLink} to="/forgot-password" variant="body2">
          Forgot Password
        </Link>
      </Box>
    </Container>
  );
};

export default AuthLayout;