import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Button,
  Grid,
  Card,
  CardContent,
  Box,
  Avatar,
  Tooltip,
} from "@mui/material";

import { useAuth } from "../contexts/AuthContext";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Confetti from "react-confetti";
import { keyframes } from "@emotion/react";
import io from "socket.io-client";
import DialogueBox from "./DialogueBox";

const API_BASE_URL =
  // process.env.REACT_APP_API_BASE_URL || "http://localhost:3004";
  process.env.REACT_APP_API_BASE_URL;
console.log("API_BASE_URL:", API_BASE_URL);

const glowAnimation = keyframes`
  0% { box-shadow: 0 0 5px #ffd700; }
  50% { box-shadow: 0 0 20px #ffd700, 0 0 30px #ffd700; }
  100% { box-shadow: 0 0 5px #ffd700; }
`;

const greenHighlightAnimation = keyframes`
  0% { background-color: transparent; }
  50% { background-color: rgba(144, 238, 144, 0.5); }
  100% { background-color: transparent; }
`;

const yellowHighlightAnimation = keyframes`
  0% { background-color: transparent; }
  50% { background-color: rgba(255, 255, 0, 0.3); }
  100% { background-color: transparent; }
`;

const fadeInOut = keyframes`
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
`;

const Scoreboard = () => {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { user } = useAuth();
  const [showConfetti, setShowConfetti] = useState(false);
  const prevAgentsRef = useRef([]);
  const [showFUBKEYDialog, setShowFUBKeyDialog] = useState(false);

  useEffect(() => {
    if (user && user.subscriptionStatus === "active" && !user.fubApiKey) {
      setShowFUBKeyDialog(true);
      console.log(user, "user hg");
      // return;
    }
  }, [user]);

  useEffect(() => {
    console.log("Current user:", user);
    if (user) {
      console.log("User is authenticated, fetching rankings...");
      fetchLiveRankings();
      const interval = setInterval(fetchLiveRankings, 60000); // Fetch every minute
      const cleanupWebSocket = setupWebSocket();
      return () => {
        clearInterval(interval);
        cleanupWebSocket();
      };
    } else {
      console.log("User is not authenticated");
      setLoading(false);
      setError("Please log in to view rankings");
    }
  }, [user]);

  useEffect(() => {
    prevAgentsRef.current = agents;
  }, [agents]);

  const setupWebSocket = () => {
    console.log("Setting up WebSocket connection...");
    const socket = io(API_BASE_URL, {
      auth: {
        token: localStorage.getItem("token"),
      },
    });

    socket.on("connect", () => {
      console.log("Connected to WebSocket");
    });

    socket.on("rankingsUpdate", (updatedRankings) => {
      console.log("Received updated rankings via WebSocket:", updatedRankings);
      processRankings(updatedRankings.agents);
    });

    socket.on("error", (error) => {
      console.error("WebSocket error:", error);
      setError("Error receiving real-time updates");
    });

    return () => {
      console.log("Disconnecting WebSocket");
      socket.disconnect();
    };
  };

  const fetchLiveRankings = async () => {
    try {
      console.log("Fetching live rankings...");
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No authentication token found");
      }
      const response = await fetch(`${API_BASE_URL}/api/live-rankings`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Received live rankings:", data);
      processRankings(data.agents);
    } catch (error) {
      console.error("Error fetching live rankings:", error);
      // setError(`Failed to fetch live rankings: ${error.message}`);
      setError("Select Metrics to view live rankings");
    } finally {
      setLoading(false);
    }
  };

  const processRankings = (rankings) => {
    console.log("Processing rankings:", rankings);
    console.log("Previous agents:", prevAgentsRef.current);
    if (Array.isArray(rankings)) {
      if (rankings.length === 0) {
        console.log("No rankings available");
        setAgents([]);
        setError("No rankings available at the moment. Check back later!");
      } else {
        let currentRank = 1;
        let previousScore = null;
        let newSmartListZero = false;
        const prevAgents = prevAgentsRef.current;
        const rankedAgents = rankings.map((agent, index) => {
          if (previousScore !== null && agent.total !== previousScore) {
            currentRank = index + 1;
          }
          previousScore = agent.total;
          const prevAgent = prevAgents.find((a) => a.agentId === agent.agentId);
          const scoreChanged = prevAgent
            ? prevAgent.total !== agent.total
            : false;
          const scoreImproved =
            scoreChanged && prevAgent ? agent.total < prevAgent.total : false;
          const rankChanged = prevAgent && prevAgent.rank !== currentRank;
          const rankImproved = rankChanged && currentRank < prevAgent.rank;

          if (agent.total === 0 && prevAgent && prevAgent.total !== 0) {
            newSmartListZero = true;
          }

          console.log(
            `Agent ${agent.agentName}: Previous rank: ${prevAgent?.rank}, Current rank: ${currentRank}, Rank changed: ${rankChanged}, Rank improved: ${rankImproved}`
          );

          const newAgent = {
            ...agent,
            rank: currentRank,
            highlight: scoreChanged,
            highlightColor: scoreChanged
              ? scoreImproved
                ? "green"
                : "yellow"
              : null,
            rankChange: rankChanged
              ? {
                  direction: rankImproved ? "up" : "down",
                  timestamp: Date.now(),
                }
              : null,
          };

          return newAgent;
        });

        console.log("Ranked agents:", rankedAgents);
        setAgents(rankedAgents);
        setError("");

        if (newSmartListZero) {
          setShowConfetti(true);
          setTimeout(() => setShowConfetti(false), 10000);
        }
      }
    } else {
      console.error("Unexpected data format:", rankings);
      setError("Unexpected data format from server");
    }
  };

  const isRecentRankChange = (rankChange) => {
    if (!rankChange) return false;
    const timeSinceChange = Date.now() - rankChange.timestamp;
    return timeSinceChange < 5000; // Show arrow for 5 seconds
  };

  if (!user) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Unauthorized
        </Typography>
        <Typography variant="body1" align="center">
          Please log in to view the scoreboard.
        </Typography>
      </Container>
    );
  }

  const renderRankEmoji = (rank) => {
    if (rank === 1) return "🥇";
    if (rank === 2) return "🥈";
    if (rank === 3) return "🥉";
    return rank;
  };

  const smartListZeroCount = agents.filter((agent) => agent.total === 0).length;
  if (error) {
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <CircularProgress />
    </Box>;
  }

  return (
    <>
      {showFUBKEYDialog ? (
        <DialogueBox />
      ) : (
        <Container maxWidth="md" sx={{ mt: 4 }}>
          {showConfetti && <Confetti recycle={false} numberOfPieces={200} />}
          {loading ? (
            <Grid container justifyContent="center" sx={{ mt: 4 }}>
              <CircularProgress />
            </Grid>
          ) : error ? (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              sx={{ height: "100vh" }} // Full height of viewport for vertical centering
            >
              <Grid item sx={{ textAlign: "center" }}>
                {" "}
                {/* Aligns the inner content */}
                <Typography variant="h6" color="error" align="center">
                  {error}
                </Typography>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  sx={{ mt: 2 }}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => navigate("/")}
                    >
                      Back to Select Metrics
                    </Button>
                  </Grid>
                  {/* <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate("/smart-lists")}
                    >
                      Back to Smart Lists
                    </Button>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          ) : Object.keys(agents).length === 0 ? (
            <Typography
              variant="h6"
              color="error"
              align="center"
              sx={{ mt: 4 }}
            >
              No agents selected. Please try again later.
            </Typography>
          ) : (
            <Card>
              <CardContent>
                <Typography variant="h4" align="center" gutterBottom>
                  Live Leaderboard
                </Typography>
                {smartListZeroCount > 0 && (
                  <Box sx={{ mb: 2, textAlign: "center" }}>
                    <Typography variant="h6" color="secondary">
                      🎉 Congratulations to our {smartListZeroCount} Smart List
                      Zero {smartListZeroCount === 1 ? "Achiever" : "Achievers"}
                      ! 🎉
                    </Typography>
                  </Box>
                )}
                <TableContainer component={Paper} sx={{ mt: 4 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            backgroundColor: "primary.main",
                            color: "white",
                          }}
                        >
                          Rank
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            backgroundColor: "primary.main",
                            color: "white",
                          }}
                        >
                          Agent's Name
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            backgroundColor: "primary.main",
                            color: "white",
                          }}
                        >
                          Score
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {agents.map((agent) => (
                        <TableRow
                          key={`${agent.agentId}-${agent.rank}`}
                          sx={{
                            ...(agent.total === 0 && {
                              background:
                                "linear-gradient(45deg, #FFD700 30%, #FFA500 90%)",
                              animation: `${glowAnimation} 2s infinite`,
                            }),
                            ...(agent.highlight &&
                              agent.highlightColor && {
                                animation: `${
                                  agent.highlightColor === "yellow"
                                    ? yellowHighlightAnimation
                                    : greenHighlightAnimation
                                } 3s`,
                              }),
                          }}
                        >
                          <TableCell align="center">
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Box position="relative" width={40} height={40}>
                                <Avatar
                                  sx={{
                                    bgcolor:
                                      agent.total === 0
                                        ? "secondary.main"
                                        : "primary.main",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: 40,
                                    height: 40,
                                  }}
                                >
                                  {renderRankEmoji(agent.rank)}
                                </Avatar>
                                {agent.rankChange &&
                                  isRecentRankChange(agent.rankChange) && (
                                    <Box
                                      position="absolute"
                                      top={-10}
                                      right={-10}
                                      sx={{
                                        animation: `${fadeInOut} 5s`,
                                      }}
                                    >
                                      {agent.rankChange.direction === "up" ? (
                                        <ArrowUpwardIcon
                                          color="success"
                                          fontSize="small"
                                        />
                                      ) : (
                                        <ArrowDownwardIcon
                                          color="error"
                                          fontSize="small"
                                        />
                                      )}
                                    </Box>
                                  )}
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              {agent.agentName}
                              {agent.total === 0 && (
                                <Tooltip
                                  title="Smart List Zero Achieved!"
                                  arrow
                                >
                                  <Box
                                    ml={2}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <EmojiEventsIcon
                                      sx={{
                                        color: "#FFD700",
                                        fontSize: 30,
                                      }}
                                    />
                                  </Box>
                                </Tooltip>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="h6" fontWeight="bold">
                              {agent.total}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  sx={{ mt: 4 }}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => navigate("/")}
                    >
                      Back to Select Metrics
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate("/smart-lists")}
                    >
                      Back to Smart Lists
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Container>
      )}
    </>
  );
};

export default Scoreboard;
