import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Typography,
  Card,
  CardContent,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";

import { HelpOutline } from "@mui/icons-material";

const PipelineChart = ({ stageLogs, columnOrder }) => {
  const [selectedAgent, setSelectedAgent] = useState("All Agents");
  const [chartData, setChartData] = useState([]);
  const [stageMap, setStageMap] = useState({});

  useEffect(() => {
    const fetchStageNames = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found");
        }

        const stagesResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/stages`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const map = stagesResponse.data.reduce((acc, stage) => {
          acc[stage.id] = stage.name;
          return acc;
        }, {});

        setStageMap(map);
        console.log(map);
      } catch (error) {
        console.error("Error fetching stage names:", error);
      }
    };

    fetchStageNames();
  }, []);

  useEffect(() => {
    updateChartData();
  }, [selectedAgent, stageLogs, columnOrder, stageMap]);

  const updateChartData = () => {
    console.log("Updating chart data");
    // console.log("stageLogs:", stageLogs);
    console.log("selectedAgent:", selectedAgent);
    // console.log("stageMap:", stageMap);

    if (stageLogs.length === 0 || Object.keys(stageMap).length === 0) {
      console.log("No data available");
      setChartData([]);
      return;
    }

    let dataToUse = stageLogs;

    if (selectedAgent !== "All Agents") {
      dataToUse = stageLogs.filter((log) => log.agentName === selectedAgent);
    }

    console.log("Filtered stageLogs for selected agent:", dataToUse);

    const latestLogs = dataToUse.reduce((acc, agentLog) => {
      const latestLog = agentLog.logs.reduce((latest, log) => {
        return !latest || new Date(log.date) > new Date(latest.date)
          ? log
          : latest;
      }, null);

      if (latestLog) {
        acc[agentLog.agentName] = latestLog;
      }
      return acc;
    }, {});

    console.log("Latest Logs:", latestLogs);

    const stageData = Object.values(latestLogs).reduce((acc, log) => {
      if (log.stageCounts) {
        Object.entries(log.stageCounts).forEach(([stageId, count]) => {
          const stageName = stageMap[stageId] || stageId;
          if (!acc[stageName]) acc[stageName] = 0;
          acc[stageName] += Number(count);
        });
      } else {
        console.error("Missing stageCounts for log:", log);
      }
      return acc;
    }, {});

    const order = columnOrder?.order || Object.keys(stageData);
    const labels = columnOrder?.labels || {};

    const orderedStageData = order.map((stageId) => ({
      stageName: labels[stageId] || stageMap[stageId] || stageId,
      count: stageData[labels[stageId] || stageMap[stageId] || stageId] || 0,
    }));

    console.log("Ordered Stage Data:", orderedStageData);

    setChartData(orderedStageData);
  };

  const agents = [
    "All Agents",
    ...new Set(stageLogs.map((log) => log.agentName)),
  ];
  return (
    chartData?.length > 0 && (
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Sales Pipeline
            <Tooltip title="This chart shows the number of leads in each stage of the sales pipeline.">
              <IconButton size="small">
                <HelpOutline fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>Select Agent</InputLabel>
            <Select
              value={selectedAgent}
              onChange={(e) => setSelectedAgent(e.target.value)}
              label="Select Agent"
            >
              {agents.map((agent) => (
                <MenuItem key={agent} value={agent}>
                  {agent}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {chartData.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={chartData}>
                <XAxis dataKey="stageName" />
                <YAxis />
                <RechartsTooltip />
                <Legend />
                <Bar dataKey="count" fill="#8884d8" name="Lead Count" />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <Typography>
              No pipeline data available for the selected agent.
            </Typography>
          )}
        </CardContent>
      </Card>
    )
  );
};

export default PipelineChart;
