import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Box,
  Typography,
  Pagination,
} from "@mui/material";
import axios from "axios";
import io from "socket.io-client";
import Cookies from "js-cookie";
import FilterLeadClaims from "./leadClaims/FilterLeadClaims";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { styled } from "@mui/material";
import ShowStepper from "./ShowStepper";
import ViewDetailDialogueBox from "../app-component/ViewDetailDialogueBox";
import { useAuth } from "../contexts/AuthContext";
import DialogueBox from "./DialogueBox";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

// Extend dayjs with relativeTime plugin
dayjs.extend(relativeTime);

const API_BASE_URL =
  // process.env.REACT_APP_API_BASE_URL || "http://localhost:3004";
  process.env.REACT_APP_API_BASE_URL;

const LeadClaimDashboard = () => {
  const [filteredClaimEvents, setFilteredClaimEvents] = useState([]);
  const [showFUBKEYDialog, setShowFUBKeyDialog] = useState(false);
  const { user } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageForAgentPerformance, setCurrentPageForAgentPerformance] =
    useState(1);

  const [totalPages, setTotalPages] = useState(1);
  const [totalFilteredPages, setTotalFilteredPages] = useState(1);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showStepper, setShowStepper] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [open, setOpen] = useState(false); // For managing the dialog open state
  const [data, setData] = useState([]);
  const [allClaimEvents, setAllClaimEvents] = useState([]);
  const [filterClaimErrorMessage, setFilterClaimErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [totalPagesForPerformanceMetrics, setTotalPagesForPerformanceMetrics] =
    useState("");
  const [
    filteredTotalPagesForPerformanceMetrics,
    setFilteredTotalPagesForPerformanceMetrics,
  ] = useState("");

  const formatClaimedAt = (claimedAt) => {
    const date = new Date(claimedAt);
    const now = new Date();

    // Check if the date is today
    if (
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear()
    ) {
      // Return time in "HH:mm" format
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    } else {
      // Return date in "MMM DD" format (e.g., "Oct 17")
      return date.toLocaleString("default", { month: "short", day: "numeric" });
    }
  };

  const [filterAgentPerformanceMetrics, setFilterAgentPerformanceMetrics] =
    useState([]);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  console.log(API_BASE_URL, "API_BASE_URL");

  const handleViewDetailsClick = async (leadId) => {
    console.log(leadId, "leadId");
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/claim-events/${leadId}`
      );
      console.log("API response:", response.data);
      setSelectedLead(response.data); // Store selected lead's data
      setOpen(true); // Open the dialog
    } catch (error) {
      console.error("Error fetching claim events:", error);
      setErrorMessage(`Failed to fetch claim events: ${error.message}`);
    }
  };
  const StyledButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(1.5, 3),
    fontSize: "1.1rem",
    minWidth: "180px",
    textTransform: "capitalize",
  }));

  // Get claim events

  const mergeAndSortEvents = useCallback((existingEvents, newEvents) => {
    console.log("Merging events:", { existingEvents, newEvents });
    if (!existingEvents) existingEvents = [];
    const uniqueEventsMap = new Map();

    [...existingEvents, ...newEvents].forEach((event) => {
      const eventKey = `${event.leadId}-${event.eventTimestamp}-${event.claimType}-${event.claimedAt}`;
      if (
        !uniqueEventsMap.has(eventKey) ||
        new Date(event.eventTimestamp) >
          new Date(uniqueEventsMap.get(eventKey).eventTimestamp)
      ) {
        uniqueEventsMap.set(eventKey, event);
      }
    });

    const sortedEvents = Array.from(uniqueEventsMap.values()).sort((a, b) => {
      const aTimestamp = new Date(a.eventTimestamp).getTime();
      const bTimestamp = new Date(b.eventTimestamp).getTime();
      return bTimestamp - aTimestamp; // Newest first
    });

    console.log("Sorted events:", sortedEvents);
    return sortedEvents;
  }, []);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/claim-events/simplified?page=${currentPage}`
      );
      console.log("API response:", response.data);

      if (!response.data || !Array.isArray(response.data.events)) {
        throw new Error("Invalid data structure received from API");
      }

      setFilteredClaimEvents(response.data.events); // Update to new data only
      setAllClaimEvents(response.data.events);
      setTotalPages(response.data.totalPages);
      setTotalFilteredPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching claim events:", error);
      setError(`Failed to fetch claim events: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }, [currentPage]);

  useEffect(() => {
    if (user && user.subscriptionStatus === "active" && user.fubApiKey) {
      fetchData();
    }

    const socket = io(
      // process.env.REACT_APP_BACKEND_URL || "http://localhost:3004"
      process.env.REACT_APP_API_BASE_URL
    );

    socket.on("connect", () => {
      console.log("WebSocket connected");
    });

    socket.on("leadClaimed", (newEvent) => {
      console.log("Received leadClaimed event:", newEvent);

      setFilteredClaimEvents((prevEvents) => {
        const updatedEvent = {
          leadId: newEvent.leadId,
          currentState: {
            leadName: newEvent.leadName,
            claimSource: newEvent.claimSource,
            leadStage: newEvent.leadStage,
          },
          history: [
            {
              claimTypeDetails: newEvent.claimTypeDetails,
              claimedAt: newEvent.claimedAt,
              eventTimestamp: newEvent.eventTimestamp,
              claimType: newEvent.claimType,
              timeToClaim: newEvent.timeToClaim,
            },
          ],
        };

        return mergeAndSortEvents(prevEvents, [updatedEvent]);
      });
      setAllClaimEvents((prevEvents) => {
        const updatedEvent = {
          leadId: newEvent.leadId,
          currentState: {
            leadName: newEvent.leadName,
            claimSource: newEvent.claimSource,
            leadStage: newEvent.leadStage,
          },
          history: [
            {
              claimTypeDetails: newEvent.claimTypeDetails,
              claimedAt: newEvent.claimedAt,
              eventTimestamp: newEvent.eventTimestamp,
              claimType: newEvent.claimType,
              timeToClaim: newEvent.timeToClaim,
            },
          ],
        };

        return mergeAndSortEvents(prevEvents, [updatedEvent]);
      });
    });

    socket.on("disconnect", () => {
      console.log("WebSocket disconnected");
    });

    return () => {
      console.log("Disconnecting WebSocket");
      socket.disconnect();
    };
  }, [fetchData, currentPage]);

  useEffect(() => {
    console.log("Current claimEvents state:", filteredClaimEvents);
  }, [filteredClaimEvents]);

  useEffect(() => {
    console.log("Current claimEvents state:", allClaimEvents);
  }, [allClaimEvents]);

  // useEffect(() => {
  //   console.log("Current claimEvents state:", data);
  // }, [data]);
  // useEffect(() => {
  //   console.log("Current claimEvents state:", filterAgentPerformanceMetrics);
  // }, [filterAgentPerformanceMetrics]);

  const handleClose = () => {
    setOpen(false);
  };

  const loadMore = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleRefresh = () => {
    setCurrentPage(1);
    fetchData();
  };
  useEffect(() => {
    const fetchAgentPerformance = async () => {
      setLoading(true)
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/claim-events/agent-performance?page=${currentPageForAgentPerformance}`
        );
        console.log(
          currentPageForAgentPerformance,
          "currentPageForAgentPerformance"
        );
        console.log(response.data);
        setData(response.data.agentPerformance); // Assuming response.data is an array of agents' performance data
        setFilterAgentPerformanceMetrics(response?.data.agentPerformance);
        setTotalPagesForPerformanceMetrics(response?.data.totalPages);
        setFilteredTotalPagesForPerformanceMetrics(response?.data.totalPages);
        // setAllClaimEvents(response.data)
        console.log(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching agent performance:", err);
        setErrorMessage("Failed to fetch agent performance");
        setLoading(false);
      }
    };

    if (user && user.subscriptionStatus === "active" && user.fubApiKey) {
      fetchAgentPerformance();
    }

    // fetchAgentPerformance();
  }, [currentPageForAgentPerformance]);
  // console.log(currentPageForAgentPerformance, "currentPageForAgentPerformance");

  // Fetch claim events based on filters

  useEffect(() => {
    console.log("Current claimEvents state:", filteredClaimEvents);
  }, [filteredClaimEvents]);
  useEffect(() => {
    console.log("Current claimEvents state:", allClaimEvents);
  }, [allClaimEvents]);
  useEffect(() => {
    if (user && user.subscriptionStatus === "active" && !user.fubApiKey) {
      setShowFUBKeyDialog(true);
      return;
    }
  }, [user]);
  // Handle close button

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (showFUBKEYDialog) {
    return <DialogueBox />;
  }

  console.log(filteredClaimEvents, "filteredClaimEvents");
  console.log(filterClaimErrorMessage);
  // console.log(filterClaim);
  console.log(totalPages, "totalPages");
  console.log(currentPage, "currentPage");

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const handlePageChangeForAgentPerformance = (event, page) => {
    setCurrentPageForAgentPerformance(page);
  };

  if (loading) {
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <CircularProgress />
    </Box>;
  }


  return (
    <>
       {loading && (
        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 9999 }}>
          <CircularProgress />
        </Box>
      )}
      {!loading && !showFUBKEYDialog && (
        <Box sx={{ display: "flex", flexDirection: "column" }} width="100%">
          <FilterLeadClaims
            setFilteredClaimEvents={setFilteredClaimEvents}
            setFilterAgentPerformanceMetrics={setFilterAgentPerformanceMetrics}
            data={data}
            setFilterClaimErrorMessage={setFilterClaimErrorMessage}
            setTotalPages={setTotalPages}
            setTotalFilteredPages={setTotalFilteredPages}
            totalPages={totalPages}
            allClaimEvents={allClaimEvents}
            setFilteredTotalPagesForPerformanceMetrics={setFilteredTotalPagesForPerformanceMetrics}
            filteredTotalPagesForPerformanceMetrics={filteredTotalPagesForPerformanceMetrics}
            totalPagesForPerformanceMetrics={totalPagesForPerformanceMetrics}
          />
                    {/* Agent performance metrics  */}
                    <Box sx={{ marginTop: "10px" }}>
            <Typography variant="h5" component="h5" gutterBottom>
              Agent Performance Metrics
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>AGENT</TableCell>
                    <TableCell>FTC CLAIMS</TableCell>
                    <TableCell>POND CLAIMS</TableCell>
                    <TableCell>REASSIGNED CLAIMS</TableCell>
                    <TableCell>RELEASED TO POND</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterAgentPerformanceMetrics?.length > 0 ? (
                    filterAgentPerformanceMetrics?.map((agent, index) => (
                      <TableRow key={index}>
                        <TableCell>{agent?.agentName}</TableCell>
                        <TableCell>{agent?.ftcClaims?.count}</TableCell>
                        <TableCell>{agent?.pondClaims?.count}</TableCell>
                        <TableCell>{agent?.reassignedClaims?.count}</TableCell>
                        <TableCell>
                          {agent?.releasedToPondClaims?.count}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        No data found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Box display="flex" alignItems="center" justifyContent="end">
              <Pagination
                count={filteredTotalPagesForPerformanceMetrics}
                page={currentPageForAgentPerformance}
                onChange={handlePageChangeForAgentPerformance}
              />
            </Box>
          </Box>

          {/* Lead Claims Overview */}
          <Box>
            <Typography variant="h5" component="h5" gutterBottom>
              Lead Claims Overview
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>LEAD NAME</TableCell>
                    <TableCell>CLAIM TYPE</TableCell>
                    <TableCell>From Agent</TableCell>
                    <TableCell>To Agent</TableCell>
                    <TableCell>POND</TableCell>
                    <TableCell>Lead Stage</TableCell>
                    <TableCell>DATE</TableCell>
                    <TableCell>ACTION</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredClaimEvents?.length === 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        No data found
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredClaimEvents?.map((event) =>
                      event.history.map((historyEntry, index) => {
                        const uniqueKey = `${event.leadId}-${historyEntry.claimedAt}-${index}`;
                        const claimedAtFormatted = dayjs(
                          historyEntry.claimedAt
                        ).isBefore(dayjs().subtract(1, "day"))
                          ? dayjs(historyEntry.claimedAt).format("MMM D, YYYY")
                          : dayjs(historyEntry.claimedAt).fromNow();

                        return (
                          <TableRow key={uniqueKey}>
                            {/* Lead Name */}
                            <TableCell>
                              {historyEntry.leadName || "Unknown"}
                            </TableCell>

                            {/* Claim Type */}
                            <TableCell>
                              {(() => {
                                switch (historyEntry.claimTypeDetails?.type) {
                                  case "ReleasedToPond":
                                    return "Released To Pond";
                                  case "FTC":
                                    return "First To Claim";
                                  case "Reassigned":
                                    return "Reassigned";
                                  case "ClaimedFromPond":
                                    return "Pond Claim";
                                  case "ClaimedFromGroup":
                                    return "Claimed From Group";
                                  default:
                                    return "No Change";
                                }
                              })()}
                            </TableCell>

                            {/* Agent From */}
                            <TableCell>
                              {historyEntry.claimTypeDetails?.type ===
                                "Reassigned" &&
                              historyEntry.claimTypeDetails?.previousAssignment
                                ?.assignmentType === "agent"
                                ? historyEntry.claimTypeDetails
                                    .previousAssignment.assignedToName
                                : historyEntry.claimTypeDetails?.type ===
                                    "ReleasedToPond" &&
                                  historyEntry.claimTypeDetails
                                    ?.previousAssignment?.assignmentType ===
                                    "agent"
                                ? historyEntry.claimTypeDetails
                                    .previousAssignment.assignedToName
                                : "N/A"}
                            </TableCell>

                            {/* Agent To */}
                            <TableCell>
                              {historyEntry.claimTypeDetails?.type ===
                                "ClaimedFromPond" &&
                              historyEntry.claimTypeDetails?.newAssignment
                                ?.assignmentType === "agent"
                                ? historyEntry.claimTypeDetails.newAssignment
                                    .assignedToName
                                : historyEntry.claimTypeDetails?.type ===
                                    "Reassigned" &&
                                  historyEntry.claimTypeDetails?.newAssignment
                                    ?.assignmentType === "agent"
                                ? historyEntry.claimTypeDetails.newAssignment
                                    .assignedToName
                                : "N/A"}
                            </TableCell>

                            {/* Pond (Current assignment type) */}
                            <TableCell>
                              {historyEntry.claimTypeDetails?.type ===
                                "ClaimedFromPond" &&
                              historyEntry.claimTypeDetails?.previousAssignment
                                ?.assignmentType === "pond"
                                ? historyEntry.claimTypeDetails
                                    .previousAssignment.pondName
                                : historyEntry.claimTypeDetails?.newAssignment
                                    ?.assignmentType === "pond"
                                ? historyEntry.claimTypeDetails.newAssignment
                                    .pondName
                                : "N/A"}
                            </TableCell>

                            {/* Lead Stage */}
                            <TableCell>
                              {historyEntry.leadStage || "N/A"}
                            </TableCell>

                            {/* Claim Date */}
                            <TableCell>{claimedAtFormatted}</TableCell>

                            {/* Action Button */}
                            <TableCell>
                              <StyledButton
                                variant="contained"
                                size="small"
                                onClick={() =>
                                  handleViewDetailsClick(event.leadId)
                                }
                              >
                                View Details
                              </StyledButton>
                              <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="responsive-dialog-title"
                              >
                                <DialogTitle id="responsive-dialog-title">
                                  Lead Details
                                </DialogTitle>
                                <DialogContent
                                  sx={{ height: "200px", width: "500px" }}
                                >
                                  <ShowStepper leadDetails={selectedLead} />
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={handleClose} autoFocus>
                                    Close
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="end">
            <Pagination
              count={totalFilteredPages}
              page={currentPage}
              onChange={handlePageChange}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default LeadClaimDashboard;
