"use client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// import persistStore from "redux-persist/es/persistStore";
import { persistStore } from "redux-persist";
import { store } from "../store";

// import { useRef } from "react";

const StoreProvider = ({ children }) => {
  // const storeRef = useRef();
  // if (!storeRef.current) {
  //   storeRef.current = store;
  // }
  const persistor = persistStore(store);
  return (
    // <Provider store={storeRef.current}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};

export default StoreProvider;


